<template>
  <div :id="`${component.name}SignaturePad`" class="signature-pad">
    <div class="wrapper">
      <div class="title font-md">
        <span>서명하기</span>
      </div>
      <div class="desc font-sm">본 사모 펀딩 프로젝트 인수계약서에 투자자님의 서명을 기재합니다.</div>
      <div class="pad">
        <div class="wrapper">
          <div class="body">
            <img class="sign" v-if="state.signImage"/>
            <canvas id="canvas" v-else></canvas>
          </div>
        </div>
      </div>
    </div>
    <div class="action">
      <button :id="`${component.name}Clear`" class="btn btn-secondary btn-block font-sm" @click="reset()" v-if="state.signImage">다시 서명</button>
      <div class="row" v-else>
        <div class="col pr-0">
          <button :id="`${component.name}Clear`" class="btn btn-secondary btn-block font-sm" @click="clear()">지우기</button>
        </div>
        <div class="col pl-0">
          <button :id="`${component.name}Submit`" class="btn btn-point btn-block font-sm" @click="submit()">저장</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent, nextTick, onMounted, reactive} from "@vue/composition-api";
import http from "@/scripts/http";
import store from "@/scripts/store";
import Number from "@/components/Number";
import mixin from "@/scripts/mixin";
import {httpError} from "@/scripts/httpError";

function Component(initialize) {
  this.name = "modalSignaturePad";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  components: {Number},
  setup() {
    const component = new Component(() => {
      state.signImage = modalParams.project.signImage;
      store.commit("tuneModal", {component, size: "xs"});
    });

    const state = reactive({
      signImage: ""
    });

    let signaturePad;
    const modalParams = store.getters.modalParams(component);

    const submit = () => {
      const formData = new FormData();
      formData.append("signFile", getBlob(signaturePad.toDataURL("image/png")));

      http.put(`/api/participant/${modalParams.project.subscribeSeq}/invest/sign-file`, formData).then(() => {
        store.commit("closeModal", {
          name: component.name,
          onClose(modal) {
            store.dispatch("callback", {modal});
            store.commit("setSwingMessage", "서명을 저장하였습니다.");
          }
        });
      }).catch(httpError());
    };

    const reset = () => {
      state.signImage = "";
      nextTick(loadPad);
    };

    const clear = () => {
      signaturePad.clear();
    };

    const getBlob = (dataURL) => {
      const parts = dataURL.split(";base64,");
      const contentType = parts[0].split(":")[1];
      const raw = window.atob(parts[1]);
      const rawLength = raw.length;
      const uInt8Array = new Uint8Array(rawLength);

      for (let i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i);
      }

      return new Blob([uInt8Array], {type: contentType});
    };

    const loadPad = () => {
      const canvas = document.querySelector(`#${component.name}SignaturePad canvas`);

      if (!canvas) {
        return;
      }

      signaturePad = new window.SignaturePad(canvas);

      function setCanvas() {
        const ratio = Math.max(window.devicePixelRatio || 1, 1);
        canvas.width = canvas.offsetWidth * ratio;
        canvas.height = canvas.offsetHeight * ratio;
        canvas.getContext("2d").scale(ratio, ratio);
        signaturePad.clear();
      }

      window.onresize = setCanvas;
      setCanvas();
    };

    onMounted(() => {
      store.dispatch("appendScript", {
        src: "https://cdn.jsdelivr.net/npm/signature_pad@4.0.0/dist/signature_pad.umd.min.js",
        onEveryLoad: loadPad
      });

      if (state.signImage) {
        const imgElement = document.querySelector(`#${component.name}SignaturePad img.sign`);
        imgElement.setAttribute("src", state.signImage);
      }
    });

    return {component, state, modalParams, submit, reset, clear};
  }
});
</script>

<style lang="scss" scoped>
.signature-pad {
  > .wrapper {
    background: #fff;
    padding: $px25;

    .title {
      margin-bottom: $px9;
    }

    .desc {
      margin-bottom: $px25;
      color: #666;
    }

    .pad {
      min-height: $px150;
      font-size: 0;

      > .wrapper {
        > .body {
          background: #fff;

          canvas {
            outline: $px1 solid #eee;
            width: 100%;
            height: $px150;
          }
        }
      }
    }
  }

  .action {
    button {
      padding: $px15;
      height: $px56;
      border-radius: 0;
    }
  }
}
</style>